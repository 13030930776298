const size = [
  { label: 'Large', value: 'Large' },
  { label: 'Small', value: 'Small' },
  // { label: 'Undefined', value: null },
]

const medium = [
  { label: 'Electricity', value: 'Electricity' },
  { label: 'Gas', value: 'Gas' },
  { label: 'Water', value: 'Water' },
  { label: 'Warmte', value: 'Heat' },
  { label: 'Warmtekosten', value: 'HeatCost' },
]

const task_types = [
  { label: 'Sync nodes', value: 'Sync nodes' },
  { label: 'Reset nodes', value: 'Reset nodes' },
  { label: 'Delete nodes', value: 'Delete nodes' },
]

const connector_types = [
  { label: 'Ealyze', value: 'Ealyze' },
  { label: 'B One Middleware', value: 'B One Middleware' },
  { label: 'SmartDodos', value: 'SmartDodos' },
]

const model = [
  { label: 'NAS Pulse Reader CM3061', value: 'NAS Pulse Reader CM3061' },
  { label: 'NAS Pulse Reader UM3080', value: 'NAS Pulse Reader UM3080' },
  { label: 'Elvaco CMi4140', value: 'Elvaco CMi4140' },
  { label: 'DZG LoRaMod R4', value: 'DZG LoRaMod R4' },
  { label: 'DZG LoRaMod R4 - SimpleWEG', value: 'DZG LoRaMod R4 - SimpleWEG' },
  { label: 'DZG LoRaMod R4 Frametype 2', value: 'DZG LoRaMod R4 Frametype 2' },
  { label: 'DZG LoRaMod R4 Frametype 2 - SimpleWEG', value: 'DZG LoRaMod R4 Frametype 2 - SimpleWEG' },
  { label: 'Minol/ZENNER', value: 'Minol/ZENNER' },
  { label: 'ZENNER IUWS', value: 'ZENNER IUWS' },
]

const role = [
  { label: 'Viewer', value: 'Viewer' },
  { label: 'Editor', value: 'Editor' },
]

const jaNee = [
  { label: 'Ja', value: true },
  { label: 'Nee', value: false },
]

const pipelines = [{ label: 'Data reset & sync', value: 'reset_nodes_and_sync_measurements' }]

export default {
  size,
  medium,
  task_types,
  connector_types,
  model,
  role,
  pipelines,
  jaNee,
}
